import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button, Spin } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { Table } from '../../components/tables';

import { SERVER_URL } from '../../config';

const Users = (props) => {
  const type = props.topBar === 'vp' ? 'corporate' : 'private';
  const currentuser = useContext(UserContext);
  const [users, fetchUsers] = useAxios('', [], currentuser.data.token, 'get');
  const [working, setWorking] = useState(false);

  //variables
  let filter = `?filter=${JSON.stringify({ legalType: type })}`;
  if (currentuser.data.role === 'commercialist') {
    filter = `?filter=${JSON.stringify({
      legalType: type,
      commercialist: `${currentuser.data.firstName} ${currentuser.data.lastName}`,
    })}`;
  }

  useEffect(() => {
    fetchUsers(`${SERVER_URL}/users${filter}`, []);
  }, [fetchUsers]);

  //functions

  const syncUsers = async () => {
    try {
      await Axios.get(`https://himtexcompany-api-staging.concordsofttest.com/users-sync`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Korisnici su sinhronizovani.',
        placement: 'bottomRight',
      });
      setWorking(false);
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (error) {
      setWorking(false);
      console.log(error.message);
    }
  };

  const signUp = async ({ _id, role }) => {
    try {
      await Axios.post(
        `${SERVER_URL}/log-in-admin`,
        { userId: _id },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      if (role === 'retail user') {
        if (process.env.NODE_ENV === 'development') {
          window.open('https://himtexcompany-staging.concordsofttest.com/', '_blank'); // http://localhost:6001/
        } else if (process.env.NODE_ENV === 'production') {
          window.open('https://himtexcompany-staging.concordsofttest.com/', '_blank');
        }
      } else {
        if (process.env.NODE_ENV === 'development') {
          window.open('https://himtexcompanyb2b-dashboard-staging.concordsofttest.com', '_blank'); // http://localhost:3006
        } else if (process.env.NODE_ENV === 'production') {
          window.open('https://himtexcompanyb2b-dashboard-staging.concordsofttest.com', '_blank');
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Korisnik je obrisan.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim Vas da pokušate kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  //table data

  const columnKeys = ['firstName', 'lastName', 'email'];

  let tableData = [];
  if (users.data.items && users.data.items.length > 0) {
    tableData = users.data.items.map((item) => {
      return item;
    });
    if (type === 'corporate') {
      tableData = tableData.sort((a, b) => a.companyName.localeCompare(b.companyName));
    } else {
      tableData = tableData.sort((a, b) => a.firstName.localeCompare(b.firstName));
    }
  }

  return (
    <div>
      <Spin spinning={working} tip='Sinhronizacija u toku...'>
        <div className='iconLabelContainer' style={{ display: 'flex', alignItems: 'center' }}>
          <>
            <i className='ri-file-list-2-line'></i>
            <div className='icon-label'>{props.topBar.toUpperCase()} KUPCI</div>
          </>
          <div className='sync-button' style={{ marginLeft: 'auto', marginRight: '0px' }}>
            <Button onClick={() => syncUsers()}>Sinhronizuj</Button>
          </div>
        </div>
        <div className='table table-users'>
          <div style={{ textAlign: 'center' }}>
            {users.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
            {!users.isLoading && users.data.items && users.data.items.length > 0 && (
              <Table
                type={props.topBar}
                signUp={signUp}
                data={tableData}
                deleteHandler={deleteUserHandler}
                columnKeys={columnKeys}
                title='User'
                editPath='/admin/edit-user/'
                viewPath='/admin/view-user/'
              />
            )}
            {!users.isLoading && users.data.items && users.data.items.length === 0 && <h2>NEMA PODATAKA</h2>}
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default Users;
